.page-title {
  font-size: 5rem;
  margin: 6rem 0 3.5rem;
  padding-bottom: 2rem;
  text-align: center;
  position: relative;
  font-family: NeoSansProMedium, sans-serif;
  font-weight: 300;
  &:after {
    content: "";
    width: 155px;
    border-bottom: 1px solid $highlight-color;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}

#module-crd_events-list, #module-crd_events-search {
  #scrollToTop {
    display: none;
    position: fixed;
    bottom: 290px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #00AED8;
    color: #ffffff;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;

    &:hover {
      background-color: #07adcb;
    }
  }
}

#module-crd_events-search {
  .display-type {
    margin-bottom: 15px;
  }
}

#training-listing {
  .filters-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 10px 5px rgba(160, 160, 160, 0.1);
    border-radius: 5px;

    label {
      font-size: 1.4rem;
      color: white;
    }

    select {
      height: 60px;
      font-size: 1.4rem;
      color: #a7a1a1;
      border: none;
      display: none;

      &::-webkit-scrollbar {
        width: 7px;
        margin-right: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(180, 180, 180);
        border-radius: 10px;
      }
    }

    .middle-filter {
      /*border: 1px solid white;*/
      padding-bottom: 35px;
      border-top: none;
      border-bottom: none;
    }

    #level-filter {
      float: right;
    }

    .spacing-line {
      height: 111px;
      width: 1px;
      background: rgba(255, 255, 255, 0.4);
    }

    .nice-select {
      width: 33.33%;
      @media (max-width: 769px) {
        width: 25%;
      }
      height: 60px;
      line-height: 60px;

      .list {
        display: none;
      }

      &.open {
        .list {
          display: block;
        }
      }

      span {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .option {
        white-space: normal;
        line-height: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }

      &:nth-child(2) {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;

        span {
          &:before {
            content: "\f51c";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            margin-right: 12px;
          }
        }
      }

      &:nth-child(4) {
        span {
          &:before {
            content: "\f041";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            margin-right: 12px;
          }
        }
      }

      &:nth-child(6) {
        span {
          &:before {
            content: "\f1ab";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            margin-right: 12px;
          }
        }
      }

      &:nth-child(8) {
        span {
          &:before {
            content: "\f012";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            margin-right: 12px;
          }
        }
      }

      &:nth-child(10) {
        span {
          &:before {
            content: "\f02d";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            margin-right: 12px;
          }
        }
      }

      &:last-child {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }

  .listing {
    margin-top: 5rem;
  }

  .filter-header {
    padding-bottom: 2rem;
    border-bottom: 1px solid $gray-light-second;
    margin-bottom: 2.3rem;

    h4 {
      font-size: 1.4rem;
      font-weight: normal;
      text-transform: uppercase;
      color: $content-color;
      float: left;
    }

    #reset-filters {
      float: right;
      color: #cecece;
    }
  }

  .filter-categories-title {
    font-size: 1.4rem;
    font-weight: normal;
    color: $content-color;
    margin-bottom: 2.3rem;
  }

  .filter-options {
    ul {
      li {
        margin-bottom: 18px;
        color: $light-gray;

        .audience-name-wrapper {
          max-width: 16ch;
          //overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }

    .filter-tag {
      float: left;
      margin-bottom: 0;

      span {
        text-align: center;
        display: block;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 8px;
        background-color: #EFEFEF;
        border-radius: 30px;
        float: left;
        padding: 10px 20px;
      }

      input {
        display: none;

        &:checked {
          + span {
            color: white;
            background: #07adcb;
          }
        }
      }

      &:hover {
        span {
          background: #07adcb;
          cursor: pointer;
          color: white;
        }
      }
    }
  }
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid $light-gray;
    border-radius: 2px;
    vertical-align: top;
  }

  &:focus {
    outline: none;
  }


  // Box checked
  &:checked + label:before {
    background: #07adcb;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white,
    4px 0 0 white,
    4px -2px 0 white,
    4px -4px 0 white,
    4px -6px 0 white,
    4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.filters-active {
  display: none;
}

#trainings-display {
  //border-left: 1px solid #dbdbdb;
}

#trainings-list, #limited-events-list {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .training {
    cursor: pointer;
    //width: 263px;
    position: relative;
    box-shadow: 0 0 9px 1px rgba(188, 188, 188, 0.52);
    border-radius: 9px;
    margin: 0 0 30px 0;
    float: left;

    &:hover {
      box-shadow: 0 0 9px 1px rgba(7, 173, 203, 0.52);

      .training-page-link {
        color: #00AED8;

        .fa-chevron-right {
          background: #00AED8;
          color: white;
        }
      }
    }
  }

  .training-top {
    position: relative;

    img {
      height: 170px;
      width: 263px;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
    }

    .event-ce-points {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 20px;
      right: 20px;
      background: #00AED8;
      color: #fff;
      font-family: NeoSansProMedium;
      text-align: center;
      border-radius: 50%;
      padding: 10px 0 8px 0;

      span {
        font-size: 26px;
        line-height: 30px;
        display: block;

        &.f12 {
          font-size: 14px;
          line-height: 12px;
        }
      }
    }
  }

  .training-info {
    width: 100%;
    background: #07adcb;
  }

  .training-duration {
    position: absolute;
    top: 11px;
    left: -13px;
    background: #e1f5f9;
    font-size: 1.1rem;
    color: #777;
    width: 100px;
    height: 22px;
    text-align: center;
    padding-top: 2px;
    box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.4);
    z-index: 6;
  }

  .ribbon-triangle {
    position: absolute;
    content: "";
    top: 20px;
    left: -13px;
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 13px solid #e1f5f9;
    z-index: 3;
  }

  .training-middle {
    height: 109px;
    background: #07adcb;
    color: white;
    font-weight: normal;
    width: 75%;
    float: left;
    padding: 15px 15px;

    .training-hours {
      font-size: 1.2rem;

    }

    .training-title {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 2rem;
      margin-top: 2px;
      margin-bottom: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #fff;
      font-family: GalaxiePolarisBook, sans-serif;
    }
  }

  .training-location {
    padding: 15px 0;
  }

  .info-buttons {
    float: left;
    width: 20%;
    padding: 5px 15px;

    i {
      display: block;
      margin: 0 auto;
    }

    .map-marker-icon {
      margin-bottom: 24px;
    }
  }

  .location-details {
    float: left;
    width: 80%;
    padding: 5px 18px 5px 12px;

    .location-row {
      min-height: 30px;
      margin-bottom: 10px;
    }

    .language-row {
      margin-bottom: 20px;
    }

    span, a {
      display: block;
    }

    .location-container {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }

    .training-language {
      font-size: 1.4rem;
      text-transform: uppercase;
      color: #00AED8;
      margin-top: 5px;
    }

    .training-page-link {
      margin-left: 28%;

      .fa-chevron-right {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        color: #cccccc;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: white;
        position: absolute;
        right: 15px;

        &:before {
          top: 5px;
          left: 9px;
          position: absolute;
        }
      }

    }
  }

  .list-display {
    display: none;
  }

  &.list-view {
    margin: 0;

    .grid-display {
      display: none;
    }

    .training {
      width: 100%;
      border-radius: 3px;
      margin: 0;
      margin-bottom: 12px;
      border-left: 7px solid $list-before;
      box-shadow: 0 0 10px 0 rgba(188, 188, 188, 0.26);
      padding-left: 23px;
    }

    .list-display {
      display: flex;
      align-items: center;
      min-height: 56px;

      .date {
        font-weight: bold;
      }

      > div {
        flex-basis: 100%;
        text-align: left;
      }

      .language {
        span {
          color: #fff;
          background: #00AED8;
          width: 90px;
          padding-top: 7px;
          padding-bottom: 7px;
          display: block;
          text-align: center;
          border-radius: 3px;
        }
      }

      .list-location {
        span {
          color: #ffffff;
          background: linear-gradient(180deg, #88cbe1 50%, #8ac2e8 75%, #8ab3ed 100%);
          width: 130px;
          padding-top: 7px;
          padding-bottom: 7px;
          display: block;
          text-align: center;
          border-radius: 3px;
        }
      }
    }
  }
}

.past-events-list:not(.webinars) {
  .training-top, .training-info, .list-location {
    filter: grayscale(1);
  }
}

.end-list {
  text-align: center;
}

.training .grid-display {
  .location-details {
    span {
      min-height: 17px;
    }
  }
}

.map-marker-icon {
  background-image: url('../img/map-marker.png');
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
}

.user-group-icon {
  background-image: url('../img/user-group.png');
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 23px;
  height: 23px;
  margin-top: -4px;
}

.translate-icon {
  background-image: url('../img/translate.png');
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25%;
}

.listing-top {
  margin-bottom: 34px;
  display: none;
}

.searched-term {
  color: #3cb7e0;
}

.search-no-items {
  margin-bottom: 50px;
  text-align: center;
  font-size: 16px;
}

.search-actions-wrapper {
  display: flex;
  justify-content: center;
  margin: 50px 0;

  a {
    margin: 0 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }
}

.filter-no-items {
  margin-left: 15px;
}

.list-table-head {
  margin-bottom: 22px;
  height: 56px;
  align-items: center;
  border: 1px solid $gray-lighter-second;
  box-shadow: 0 0 10px 0 rgba(188, 188, 188, 0.26);
  padding-left: 30px;
  display: none;

  &.show-list-header {
    display: flex;
  }

  span {
    text-transform: uppercase;
    flex-basis: 100%;
    text-align: left;
    font-size: 1.4rem;
    color: $content-color;
    font-weight: bold;
    /*padding-left: 20px;*/
  }
}

.display-type {
  float: right;

  .fa {
    width: 41px;
    height: 37px;
    color: $light-icon;
    background: white;
    text-align: center;
    box-shadow: 0 0 6.58px 0.42px rgba(188, 188, 188, 0.18);
    padding-top: 11px;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      color: $brand-primary;
    }

    &:before {
      font-size: 15px;
    }

    &.active {
      color: white;
      background: $brand-primary;
    }
  }

  .show_list {
    margin-left: 7px;
  }
}

#trainings-list.clearfix.list-view {
  .training {
    .list-display {
      cursor: pointer;
    }
  }
}

.loading-events {
  width: 100%;
  height: 50px;
  margin: 0 auto;
  display: none;
  text-align: center;

  span {
    display: inline-block;
    vertical-align: middle;
    margin: auto 0;
  }
}

@media (min-width: 769px) {
  //Box hover
  &:hover + label:before {
    background: #07adcb;
  }
}

@media (max-width: 1200px) {
  #module-crd_events-list, #module-crd_events-search {
    #scrollToTop {
      padding: 12px;
      font-size: 17px;
      border-radius: 7px;
      right: auto;
      left: 20px;
    }
  }

  #training-listing {
    margin: 0 -15px;
    .filters-container {
      .spacing-line {
        display: none;
      }
    }

    .audience-name-wrapper {
      max-width: 12ch !important;
    }
  }
}

.crd-datepicker {
  z-index: 2;
}

.sold-out-banner {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #00AED880;
  color: white;
  padding: 5px 0;
  margin: 0;
  font-weight: 500;
}

.list-sold-out-banner {
  font-size: 12px;
  color: #00AED8;
}

@media (max-width: 1080px) {
  .display-type {
    display: none;
  }
}

@media (max-width: 818px) {
  #trainings-list .training {
    margin: 0 auto 3rem;
  }
}

@media (max-width: 818px) {
  #trainings-list .training {
    margin: 0 auto 3rem;
  }
}

@media (max-width: 767px) {
  #module-crd_events-list, #module-crd_events-search {
    #scrollToTop {
      padding: 12px;
      font-size: 17px;
      left: auto;
      right: 14px;
      bottom: 20px;
    }
  }
  .filters-container {
    div {
      label {
        min-height: 34px;
      }
    }
  }
}

@media (max-width: 575px) {
  #trainings-display {
    border-left: none;
    padding: 20px 0 0 0 !important;
  }
  .filters {
    border-bottom: 1px solid #dbdbdb;
  }
  #training-listing .audience-name-wrapper {
    max-width: 100% !important;
  }
}

#bottom-filter-pagination {
  width: 100%;
}

.js-product-list-selection {
  .inline-display {
    display: inline-block;
  }

  .pagination {
    background: #fff;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 20px;
    padding: 3px 15px;

    .page-list {
      padding: 0;
      box-shadow: none;
    }

    li {
      text-align: center;

      a {
        border-radius: 50%;
        display: inline-block;
        font-size: 12px;
        min-width: 30px;
        height: 30px;
        line-height: 26px;
        border: 0;
        font-weight: bold;
        color: $crd-primary-color;
      }

      .spacer {
        min-width: 30px;
      }

      &.current {
        a {
          color: #fff;
          background: #00AED8;
        }
      }
    }

    .material-icons {
      font-size: 14px;
      margin-top: -2px;
    }

    .material-icons {
      font-size: 14px;
      margin-top: -2px;
    }
  }

  .filter-label {
    font-size: 12px;
    font-weight: bold;
    color: #666;
  }

  .select-title {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    height: 36px;
    border-radius: 24px;
    line-height: 34px;
    font-size: 12px;
    font-weight: bold;
    padding: 0 15px;
    color: $crd-primary-color;
    border: none;

    .material-icons {
      font-size: 16px;
      line-height: 34px;
      height: 36px;
      color: $crd-primary-color;
      margin: 2px 0 0 2px;
    }
  }

  .sort-by-row {
    justify-content: flex-end;

    .filter-box {
      margin-left: 20px;
    }
  }
}

@media (max-width: 450px) {
  #training-listing {
    .filters-container {
      padding: 1.6rem 1.5rem !important;
    }
  }
}
